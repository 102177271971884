



































































































































































































































import { useBlob } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE, PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { returnToSupplierServices } from "./services/SupplierReturn.service";
import { ResponseGetListReturnToSupplier } from "./types/supplier-return.interface";

@Component
export default class SupplierReturnListView extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  form!: WrappedFormUtils;
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    dateFrom: "ALL",
    dateTo: "ALL",
    supplierName: "ALL",
    returnNumber: "ALL",
    currency: "ALL",
  };
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branch",
      key: "branch",
      scopedSlots: { customRender: "nullable" },
      width: 100,
    },
    {
      title: this.$t("lbl_return_number"),
      dataIndex: "returnNumber",
      key: "returnNumber",
      scopedSlots: { customRender: "nullable" },
      // width: 200,
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      scopedSlots: { customRender: "nullable" },
      // width: 200,
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "returnDate",
      key: "createdDate",
      scopedSlots: { customRender: "date" },
      // width: 150,
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      scopedSlots: { customRender: "nullable" },
      width: 100,
    },
    {
      title: this.$t("lbl_total_return"),
      dataIndex: "totalReturn",
      key: "totalReturn",
      scopedSlots: { customRender: "currency" },
      // width: 200,
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "nullable" },
      ellipsis: true,
      // width: 200,
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      key: "journalNumber",
      scopedSlots: { customRender: "journal" },
      // width: 150,
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      // width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  dataList: ResponseGetListReturnToSupplier = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };
  formRules = {
    dateFrom: {
      decorator: ["dateFrom"],
    },
    dateTo: {
      decorator: ["dateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    supplierName: {
      decorator: ["supplierName"],
    },
    returnNumber: {
      decorator: ["returnNumber"],
    },
    currency: {
      decorator: ["currency"],
    },
  };

  dataBranch = [] as DataWarehouseBranch[];
  dataSupplierName = [] as ContactData[];
  dataReturnNumber = [] as any[];
  dataCurrency = [] as DataMasterCurrency[];
  loading = {
    branch: false,
    table: false,
    find: false,
    download: false,
    returnNumber: false,
    currency: false,
    supplierName: false,
  };

  pagination = {
    limit: 10,
    page: ONE,
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "SupplierReturnList" });
  }

  mounted() {
    this.getBranch("");
    this.getSupplier("");
    this.getCurrency("");
    this.getReturnNumber("");
  }

  searchDropdown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          this.getBranch(value);

          break;
        case "supplierName":
          this.getSupplier(value);

          break;
        case "currency":
          this.getCurrency(value);
          break;
        case "returnNumber":
          this.getReturnNumber(value);
          break;
        default:
          break;
      }
    }
  }
  getReturnNumber(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    if (value) params.search = `documentNo~*${value}*`;
    this.loading.returnNumber = true;
    returnToSupplierServices
      .getListReturnToSupplier(params)
      .then(res => {
        this.dataReturnNumber = res.data.filter(item => item.returnNumber);
      })
      .finally(() => (this.loading.returnNumber = false));
  }
  getCurrency(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  getSupplier(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) {
      params.search = ` supplier~true_AND_active~true_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    } else {
      params.search = ` supplier~true_AND_active~true`;
    }
    this.loading.supplierName = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataSupplierName = data.data;
      })
      .finally(() => (this.loading.supplierName = false));
  }

  findData(): void {
    const field = this.form.getFieldsValue();
    const params = new RequestQueryParams();
    params.search = this.dynamicSearch(field);
    params.page = this.pagination.page - ONE;
    params.limit = this.pagination.limit;
    params.sorts = "createdDate:desc";

    for (const key in this.downloadParam) {
      if (key != "companyName") {
        this.downloadParam[key] = "ALL";
      }
    }

    this.getData(params);
  }

  getData(params: RequestQueryParams): void {
    this.loading.find = true;
    returnToSupplierServices
      .getListReturnToSupplier(params)
      .then(res => {
        this.dataList = res;
      })
      .finally(() => (this.loading.find = false));
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "dateFrom" && value) {
      this.downloadParam.dateFrom = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `returnDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "dateTo" && value) {
      this.downloadParam.dateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `returnDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else if (key === "returnNumber" && value) {
      this.downloadParam.returnNumber = this.dataReturnNumber.find(
        item => item.id == value
      )?.returnNumber;
      return (
        operator +
        `documentNo~${
          this.dataReturnNumber.find(item => item.id == value)?.returnNumber
        }`
      );
    } else if (key === "currency" && value) {
      this.downloadParam.currency = this.dataCurrency.find(
        item => item.id == value
      )?.currencyCode as string;
      return (
        operator +
        `currency~${
          this.dataCurrency.find(item => item.id == value)
            ?.currencyCode as string
        }`
      );
    } else if (key === "supplierName" && value) {
      this.downloadParam.supplierName = this.dataSupplierName.find(
        item => item.id == value
      )?.firstName as string;
      return operator + `supplier.secureId~${value}`;
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }

  download(): void {
    const { toDownload } = useBlob();
    const field = this.form.getFieldsValue();
    const params: RequestQueryParamsModel = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.supplierName},${this.downloadParam.dateFrom},${this.downloadParam.dateTo},${this.downloadParam.returnNumber},${this.downloadParam.currency}`,
      search: this.dynamicSearch(field),
    };
    this.loading.download = true;
    returnToSupplierServices
      .downloadReturnToSupplier(params)
      .then(res => {
        toDownload(res, "return-to-supplier.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.pagination.page = pagination.current;

    if (this.pagination.limit !== pagination.pageSize) {
      this.pagination.page = ONE;
    }

    this.pagination.limit = pagination.pageSize;

    this.findData();
  }
}
