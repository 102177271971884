// Core
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  RequestReceivingItems,
  ResponseListOfReceivingItems,
} from "@/models/interface/logistic.interface";
import {
  DataRequestReturnToSupplier,
  DataResponseCreateReturnToSupplier,
  ResponseDetailReturnToSupplier,
  ResponseGetListReturnToSupplier,
} from "../types/supplier-return.interface";

export class SupplierReturnService extends HttpClient {
  getListReturnToSupplier(
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListReturnToSupplier> {
    return this.get<ResponseGetListReturnToSupplier>(Api.ReturnToSupplier, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  getDetailReturnToSupplier(
    id: string
  ): Promise<ResponseDetailReturnToSupplier> {
    return this.get<ResponseDetailReturnToSupplier>(
      Api.ReturnToSupplier + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  createReturnToSupplier(
    payload: DataRequestReturnToSupplier
  ): Promise<DataResponseCreateReturnToSupplier> {
    return this.post<
      DataResponseCreateReturnToSupplier,
      DataRequestReturnToSupplier
    >(Api.ReturnToSupplier, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  downloadReturnToSupplier = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReturnToSupplier + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getListGrReturnToSupplier = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> => {
    return this.get<ResponseListOfReceivingItems>(Api.GrReturnToSupplier, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getDetailGrReturnToSupplier(
    params: RequestQueryParamsModel,
    path: string
  ): Promise<RequestReceivingItems> {
    return this.get<RequestReceivingItems>(
      Api.GrDetailReturnToSupplier + `/${path}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const returnToSupplierServices = new SupplierReturnService();
